/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.title-text{
  font-size: 15px !important;
  font-weight: 550 !important;
  text-transform: uppercase !important;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
}

.justifyText{
  text-align: justify;
}
.blockquote-custom {
    position: relative;
    font-size: 14px !important;
    /* width: calc(100% - 173px); */
    padding:28px;

  }
  
  .blockquote-custom-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -25px;
    left: 50px;
  }

  .blockquote-custom-date {
    width: 176px;
    height: 24px;
    border-radius: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
background-color: rgb(7, 123, 196) !important;
color:rgb(247, 245, 245);
    position: absolute;
    padding: 3px;
    top: -10px;
    right: 10px;
  }
  
  .blockquote-custom-relative-date{
    width: 156px;
    height: 24px;
    border-radius: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
background-color: rgb(255, 255, 255) !important;
/* color:white; */
    position: absolute;
    padding: 3px;
    top: -10px;
    right: 210px;
  
  }

  .blockquote-custom-close {
    width: 70px;
    height: 24px;
    border-radius: 7%;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    padding: 2px !important;
    bottom: -15px;
    right: 10px;
    background-color: rgb(80, 6, 6) !important;
    color:white !important;
    cursor:pointer;
  }
  
  
  .bg-light-custome {

    background-color:rgb(242, 242, 242);

  }

  .blockquote-custom-menu{

    width: 25px;
    height: 100%;
 
    display: flex;
    align-items: center;
    justify-content: center;
background-color: rgb(2, 0, 0) !important;
/* color:white; */
    position: absolute;
    padding: 3px;
    top: 0px;
    right: -20px;
  }

  .blockquote-custom-menu ul li i{
    color:white !important;   
 
  }

  .blockquote-custom-menu ul{
    list-style: none !important;
  }

  .blockquote-custom-menu ul li {
  margin-right:1.9rem;
  padding-top:50%;
  padding-bottom:50%;

  }

  .checkboxCustom{


/* color:white; */
    position: absolute;
    padding: 3px;
    top: -10px;
    left: -10px;
    z-index:1
  }


  @media only screen and (max-width: 760px) {

.title-text{
  font-size: 13px !important;

}
    .blockquote-custom {
      /* width: calc(100% - 103px); */
      padding:28px
    }
    .handleMedia{
      width: calc(100% - 103px) !important;

    }
    .blockquote-custom p{
      font-size: 12px;
    }
    .blockquote-custom div img{
      width: 25px;
    }

    .blockquote-custom-icon {
      width: 35px;
      height: 35px;
      top: -15px;
      left: 25px;
    }

    .blockquote-custom-date {
     
   font-size: 12px;
   width: 150px;

    }

.blockquote-custom-relative-date{
  font-size: 12px;
   width: 150px;
border-top-left-radius: 0px;
border-top-right-radius: 0px;
   top: 10px;
   right: 10px;

}

    .blockquote-custom-close {
     width: 40px;
      font-size: 10px !important;
       }
    footer{
      font-size:12px
    }
  }


  .message-container.hide{
    display: none;
    animation: fadeIn 5s;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
.main-content{
margin:0px;
padding-left: 210px;
    font-family: 'Ropa Sans', sans-serif;
    background-color: #094274;
    text-align: center;
    
    color: #fff;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}



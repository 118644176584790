
.bill-entry-addition-button{
padding: 6px 12px 6px 12px;
background-color: #094274;
color:white;
float: right;
cursor: pointer;
}

.bill-remove-addition-button{
  padding: 6px 12px 6px 12px;
background-color: #9d1515;
color:white;
float: left;
cursor: pointer;

}
.bill-remove-addition-button:hover{
  background-color: rgb(103, 10, 10);
}

.checkbox-container{
  position: absolute;
  top:-10px;
  z-index: 1;
  left:-10px;
}
.outer-container{
padding:33px 15px 35px 15px

}
/* .listSection .row{

} */

/* .row{
  padding:0px 15px 0px 0px;
} */

.gap{
    height:14px;
}

.all-headers{
 
  background:#094274 !important;
  color:white !important;
  border:none !important;
  min-width: 100%;

padding:7px 13px 7px 7px !important;

}


.all-headers-fixed{
    position:fixed !important;
    top:0px !important; 
 
    border-radius: 0px !important;

z-index: 3;
background:#094274 !important;
color:white !important;
animation: fadeIn 0.5s;
padding:6px !important;
min-width: 100% !important;
border:none !important;
}



.col span{
font-size: 13px;
font-weight: 500;
text-align: center;

}

.listSection{

    padding:7px


}






@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


  .lineContiner{
    border: 0;
    background-color: rgba(167, 129, 27, 0.906);
    height: 1px;
    cursor: pointer;
    }

  .imgStyle {
/* width:135px; */
width:99%;
height:100%;
position: relative;

/* border:solid 1px red; */
/* border-radius:4px 0px 0px 4px; */

/* z-index: 11; */
}
  .imgStyle span img{
width: 100%;
height: 100%;

/* border-radius:4px 0px 0px 4px; */

z-index: 11;


  }

  .badge-container{
    position: absolute;
	top: -8px;
	left: -4px;
	padding: 3px 9px;
	border-radius: 50%;
	font-size:11px;
	background-color: rgb(7, 47, 80);
	color:white;
  border: solid 2px white;
  z-index: 999;
  }

  .floating-btn-container{

    border-radius: 26.5px;
	background-color: #13202c;
    border: 1px solid #13202c;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	position: fixed;
	bottom: 20px;
	right: 20px;

	z-index: 999;

  }

  .floating-btn-container:hover {
	background-color: #000000;
	color: #fefefe;


}


.outer-layer-icon-bar{
 padding-left: 12px !important;
 width:12px !important;

}
.listSection .row{
  padding-right: 11px !important;
}

.icon-bar-container {
    width: 30px;
  
	background-color:#000000;
position:absolute;
right:-5px;
top:0px;
bottom:0px;

border-radius:0px 3px 3px 0px
  }
  
  
  .icon-bar-container a {
    cursor:pointer;
    
    display: block;
    text-align: center;
    padding-top:9px;
	padding-bottom:9px; 
padding-left: 0px;
padding-right: 0px;

    transition: all 0.3s ease;
    color: white;
    font-size: 14px;
    /* border-left:solid 4px rgb(9, 56, 113); */
  }

  .icon-bar-container a i {

    color: white;

  }
  
  .icon-bar-container a:hover {

    background-color: rgb(155, 119, 12);
    color: white;
    border-radius:0px 3px 3px 0px

  }


  .listSecitionInside span{
    font-size: 12px;
  }


  .titleCont{
    font-weight: 530 !important;
    color:rgb(8, 54, 88);
  }

  .fileuploadcontainer{
    padding:0px 12px 0px 12px;
  }
  .fileuploadcontainer label {
    background-color: rgba(10, 46, 90, 0.913);
    color: white;
    padding: 3px 10px 3px 35px;
    font-family: sans-serif;
    border-radius: 0.3rem;
    cursor: pointer;
    min-width: 240px;
    margin-top: 1rem;

  }

 
  input[type="file"] {
    display: none;
}
.custom-file-upload-container {
    border: 1px solid #ccc;
    display: inline-block;
    /* padding: 6px 12px; */
    cursor: pointer;
}

.custom-file-upload-container:hover{
  background-color:rgb(0, 0, 0);
}


.file-icon{
  position:relative;
left:-25px;
}

.file-icon i{

position: absolute;
left:0px;
top:3px
} 


.modal-images-download{
border:none;
border-radius: 12px;
padding:3px 10px;

}

.modal-images-download:hover{
background:#ffffff7e;

}

.delete-selected-autos{
  border: none;
  padding: 2px 12px;
  color:rgb(151, 8, 8);
font-weight: 480;
  font-size:12px;
border-radius: 4px;
text-decoration: none;
background:white;
white-space: nowrap;
cursor: pointer;
}




@media only screen and (max-width: 460px) {

  .floating-btn-container{

	font-size: 10px;
	padding: 4px 8px;
	bottom: 15px;
	right: 60px;


  }
}
.paginate{

    display: flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    bottom:0px;

}


.floating-search {
	border-radius: 10px;
	background-color: #ffffff;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 0px 0px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 999;
}

.floating-search:hover {
	background-color: #ffffff;
	color: #fefefe;


}

.floating-search:focus {
	outline: none;
}

.floating-text {
	background-color: #001F61;
	border-radius: 10px 10px 0 0;
	color: #fff;
	font-family: 'Muli';
	padding: 7px 15px;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	z-index: 998;
}

.floating-text a {
	color: #FF7500;
	text-decoration: none;
}

@media screen and (max-width: 760px) {

	.floating-search {
      /* display:none !important; */
		right: 10px !important;
        bottom: 56px !important;
	}

}

@media screen and (max-width: 470px) {

	.floating-search {
      /* display:none !important; */
	  
	  right: 10px !important;
	  bottom: 56px !important;
	 
	}

}




@media only screen and (max-width: 460px) {

    .paginate{
		justify-content: left;
		align-items: left;
font-size: 9px;
   
    }
}

.fixbar {
    width: auto;
    height:70px;
    position: sticky;
    left: 0px;
    top: 0px;
    right:0;
    /* background:white; */
  
    background:transparent;
    z-index: 1;
    /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
  
  }
 
  .beforeFixbar {
    width: auto;
    height:10px;
    position: sticky;
    left: 0px;
    top: 0px;
    right:0;
    /* background:white; */
  
    background:transparent;
    z-index: 1;
    /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
  
  }

.addtxt{
	padding-top: 10px;
	padding-bottom: 10px;
	text-align: center;
	font-size: 13px;
	width: 350px;
	background-color: #e5e8ed;
	font-weight: 500;
}
.form-control:focus{
	color: #000;
}

.adminSecond{
	width:80%;
	background-color: rgba(31, 151, 243, 0.152);
	border-radius: 4px;
  
	box-shadow: 10px 10px 5px #aaaaaa;
}
.adminOutLayer::before{

    content:'';
    width: 30px;
    height: 30px;
    border-radius: 12px;
    /* background-color: red; */
    z-index:12;
}

.adminText3{
	font-size: 13px;
    font-weight: 500;
    margin-right: 4px;
    color: #050202;
}
.adminText1{
	font-size: 13px;
    font-weight: 500;
    color: #000000;
}

.adminText2{
	font-size: 13px;
    font-weight: 500;
    margin-left: 6px;
    color: #116fa5;
}

.second{
	width:80%;
	background-color: white;
	border-radius: 4px;
	box-shadow: 10px 10px 5px #aaaaaa;
}

.text1{
	font-size: 13px;
    font-weight: 500;
    color: #56575b;
    word-wrap: break-word;
}
.textRight{
    text-align: right;
    float: right;
    font-size:10px;
}

.text2{
	font-size: 11px;
    font-weight: 500;
    margin-left: 6px;
    color: #00a5f4;
}
.text3{
	font-size: 13px;
    font-weight: 500;
    margin-right: 4px;
    color: #828386;
}
.text3{
	color: #00a5f4;

}
.text4{
	font-size: 10px;
    font-weight: 500;
    color: #828386;
}


.text4i{
	color: #00a5f4;
}
.text4o{
	color: white;
}
.thumbup{
	font-size: 13px;
    font-weight: 500;
    margin-right: 5px;
}
.thumbupo{
	color: #17a2b8;
}

.textAreaContainer{
    position: fixed;
    bottom:5px;
    left:50px;
}

.txtarea textarea{

    background:rgb(243, 239, 239);
    color:rgb(18, 124, 200);
    border-radius: 12px;
    border-color:rgb(234, 231, 231);
}


.textRightColor{
    color:rgb(0, 0, 0);
}

.admintextColor{
    color:black;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
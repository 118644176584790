/*!
 * Copyright sabaoon bedar
 */
 @import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.language-login:hover{
color:black !important;
}


.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 100px;
  height: 100px;
}


.body-color{
  position: relative;
  background:linear-gradient(#094274,rgba(4, 61, 146, 0.837));
  background-repeat: no-repeat;
  background-size: auto;
  min-height: 100vh;

}

.shade-background{
  
  background:linear-gradient(#094274,rgba(4, 24, 54, 0.837));
  width: 100%;
  height:100%;
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  z-index: 3;
  
}

.img-logo{
  width: 180px;
  height:100px;
  position: relative;
  z-index:4

  }
  .img-logo-background-container{
    /* display: flex;
    justify-content: center;
    align-self: center; */
    position:fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% - 160px);
   height:calc(100%-60px);
/* height: 80%; */
    
  }
  .img-logo-background{

    position: fixed;
    width: 100%;

  
  
    }
 

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }



.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; 

}


.ftco-section {
  
padding:3em;
min-height: 100vh;



}

.ftco-no-pt {
  padding-top: 0; }

.ftco-no-pb {
  padding-bottom: 0; }

.heading-section {
  font-size: 28px;
  color: #000; }

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.wrap {
  width: 100%;
  overflow: hidden;
  background: #fff;
  /* border-radius: 5px; */
  -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  position: relative;

}
.login-form-outer-container{
  position: relative;
  width: 100%;
  z-index:4

}
.login-buttons-container{
  position: absolute;
  top:13px;
  z-index:3
}
 .login-buttons-container a {
cursor: pointer !important;
padding: 13px;
background-color: rgb(18, 17, 17);
color:#e3b04b;
text-transform: uppercase;
}

.login-buttons-container a:hover {
  cursor: pointer !important;
  padding: 13px;
  background-color: rgb(231, 230, 230);
  color:#000000;
  text-transform: uppercase;
  }

.login-buttons-container a span{
  padding-right:18px;
}

.login-button{
  border-top-left-radius:12px ;


}
.track-vehicle-button{
  border-top-right-radius:12px ;

}

.login-buttons-container a.activeLoginButton{

  color:#000000;
  background-color: rgb(255, 255, 255);


}

/* these are the media queries to adjust the image */
@media (max-width: 1105px) {
  .img-logo-background{
  top:10%
    }
}

@media (min-width: 1371px) {
  .img-logo-background{
  top:-25%
    }
}

@media (max-width: 1455px) {
  .img-logo-background{
  top:0%
    }
}
@media (min-width: 1835px) {
  .img-logo-background{
  top:-39%
    }
}

.img, .login-wrap {
  width: 100%; }
  @media (max-width: 991.98px) {
    .img, .login-wrap {
      width: 100%; } }

@media (max-width: 767.98px) {
  .wrap .img {
    height: 250px; } }

.login-wrap {
  position: relative;
  background: #fff h3;

}

.form-group {
  position: relative; }
  .form-group .label {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000;
    font-weight: 700; }
  .form-group a {
    color: gray; }

.form-control {
  height: 48px;
  background: #fff;
  color: #000;
  font-size: 16px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.2) !important; }
  .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.2) !important; }
  .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.2) !important; }
  .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.2) !important; }
  .form-control:focus, .form-control:active {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #e3b04b; }

.social-media {
  position: relative;
  width: 100%; }
  .social-media .social-icon {
    display: block;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 16px;
    margin-right: 5px;
    border-radius: 50%; }
    .social-media .social-icon span {
      color: #999999; }
    .social-media .social-icon:hover, .social-media .social-icon:focus {
      background: #e3b04b; }
      .social-media .social-icon:hover span, .social-media .social-icon:focus span {
        color: #fff; }

.checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.checkbox-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "\f0c8";
  font-family: "FontAwesome";
  position: absolute;
  color: rgba(0, 0, 0, 0.1);
  font-size: 20px;
  margin-top: -4px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  @media (prefers-reduced-motion: reduce) {
    .checkmark:after {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

/* Show the checkmark when checked */
.checkbox-wrap input:checked ~ .checkmark:after {
  display: block;
  content: "\f14a";
  font-family: "FontAwesome";
  color: rgba(0, 0, 0, 0.2); }

/* Style the checkmark/indicator */
.checkbox-primary {
  color: #094274; }
  .checkbox-primary input:checked ~ .checkmark:after {
    color: #094274; }

.btn {
  cursor: pointer;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  font-size: 15px;
  padding: 10px 20px;
  background: #094274!important;
  color:white !important;

 }

    .btn:hover {
     
 
      color: #ffffff !important; 
      background: #132e57 !important;

    }
 


    /* css for modal of tracker*/

    .tracker-modal .modal-content {
      background:linear-gradient(#094274,rgb(4, 61, 146));
      color: #fff;
      scroll-behavior:none !important;

      /* color:white !important; */
    }

    .modal-img{
      display: flex;
    justify-content: center;
    align-self: center;

    }
.modal-img{
  position:fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height:100%;

}
     .modal-shade {
      background:linear-gradient(#094274,rgba(4, 24, 54, 0.81));
      /* width: 100%;
      height:100%; */
      height: 100%;
      position:fixed;
      top:0px;
      bottom:0px;
      left:0px;
      right:0px;
      z-index: 3;
      

      /* color:white !important; */
    }

    .modal-container{
      padding:12px 40px 12px 12px; 
      position: relative;
      z-index: 4;
      /* border: solid 1px red; */
    }

    .modal-cross{
      position:fixed;
       right:1%;
       top:1%;
       z-index:99999;
       background:rgba(1, 170, 255, 0.38) !important;
       cursor: pointer;
    }

    

    .auto-main-detail-card{
      padding: 10px;
  color:rgb(255, 255, 255);
      background: rgba(214, 166, 10, 0.818);
      border-radius: 4px;
      text-align: center;
      font-weight: 600;
      text-decoration: none;
      word-spacing: 6px;
      letter-spacing: 3px;
      text-shadow: 5px;
      /* box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.118);     */

    }

    .auto-main-detail-card-2{
      padding: 10px;
  color:rgb(255, 255, 255);
      background: rgba(214, 166, 10, 0.818);
      border-radius: 4px;
      text-align: center;
      font-weight: 600;
      text-decoration: none;
      word-spacing: 6px;
      /* letter-spacing: 3px; */
      text-shadow: 5px;
      /* box-shadow: 12px 12px 2px 1px rgba(0, 0, 0, 0.118);     */

    }

    .auto-general-details-card{
      padding: 10px;
  color:rgb(255, 255, 255);
      background: rgba(10, 95, 187, 0.188);
    
      text-decoration: none;
      word-spacing: 6px;
      letter-spacing: 3px;
      text-shadow: 5px;
      font-size: 12px;
      /* border-left: solid 2px rgb(214, 166, 10); */
      /* border-bottom: 1px solid white; */
    }

  
 
    /* .custom-padding1{
      padding:0px 0px 0px 12px !important;
    }

    .custom-padding2{
      padding:0px 12px 0px 0px !important;
    } */

    /* .custom-padding-left{
      padding:12px 1px 12px 2px !important
    } */

    .card-data{
      background: rgba(10, 95, 187, 0.188);

    }

    .card-data{
      padding:3px 4px;
    }

    .extra-info-card{
      background: rgba(1, 94, 148, 0.284);
      padding:7px;
      font-family:'Courier New', Courier, monospace;
      padding-left:12px;
      padding-right:12px;
    }
    

    .extra-details-container{
      padding: 0px 0px 0px 52px
    }

    .auto-general-details-card div{
      display: inline-block;
    }


    .extra-info-card div{
      display: inline-block;
      font-family:'Courier New', Courier, monospace;
    }

    .extra-info-card div:nth-of-type(1){
color:rgb(255, 204, 0);
padding-left:10px

    }

    .extra-info-card div:nth-of-type(2){
      padding-left: 15px;
    }


    .auto-general-details-card div:nth-of-type(1){
      color:rgb(255, 204, 0);
      padding-left:10px
    }

    .auto-general-details-card div:nth-of-type(2){

    padding-left: 15px;
    }
  

 
/* Google Fonts Import Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}




.sideMenuActiveTab{
  
  background: #0d7fd652;
  border-left: 3px solid rgb(255, 219, 12);
  color:white;
     /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
border-radius: 2px;

}


.sideSubMenuActiveTab{
  
  background: #0d7fd652;
  border-left: 1px solid rgb(255, 219, 12);
  color:white;
  padding:2px;
  text-align:right;
     /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
border-radius: 2px;

}


.active span{
  padding-left:4px;
  color:white;
  opacity:1;

}
.icon i {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  float: right;
  font-size: 12px;
  padding-left: 25px;
  width: 25px;
  height: 25px;

  color: white;
  z-index: 1000;
}

.icon::before {
  content: "";
  z-index: 1000;
  position: absolute;
  right: -17px;
  display: inline-block;
  width: 17px;
  height: 25px;

  /* border-top-right-radius: 12px;
  border-bottom-right-radius: 12px; */

  background-color: #094274;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 180px;
  background: #094274;
  z-index: 100;
  transition: all 0.5s ease;

}
.sidebar.close {
  width: 78px;
  background: #094274;
}

.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details img {
  height: 40px;
  color: #fff;
  min-width: 52px;
  filter: "brightness(0) invert(1)";
}
.sidebar .logo-details i {
  font-size: 25px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 220px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  font-weight: 100;
  position: relative;
  list-style: none;

  border-left: 2px solid transparent;
}

.sidebar .nav-links > li:hover {
  /* background: #8f752c; */
  /* border-left: 2px solid rgb(255, 219, 12); */
  /* color:white; */
  font-weight: bold;
  background:rgba(22, 117, 225, 0.188);
 

}



.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;


}
.sidebar.close .nav-links li .iocn-link {
  display: block;
}
.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
 
}
.sidebar .nav-links li a .link_name {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #094274;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 13px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.8;
  transition: all 0.1s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
  

}
/* these are the menus which are pop up to the side */
/* //in here the side menu comes when you close the sidebar */

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
  background-color: rgb(0, 0, 0);
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #094274;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 12px;
}

/* content section starts from here */

.home-section {
  position: relative;
  /* background: url("../components/assets/bg-login.jpg"); */
  background: #094274;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  left: 180px;
  width: calc(100% - 180px);
  margin-top: 0;
  padding: 0;
  transition: all 0.5s ease;

}
.home-content {
 
 /* background:rgba(209, 205, 205, 0.272);  */
   /* background-size: cover; 
  background-repeat: no-repeat;  */
  padding: 0px 8px 40px 17px;

  overflow-x: hidden;


}

.sidebar.close ~ .home-section {
  
  left: 78px;
  width: calc(100% - 78px);
}


/* .home-section .home-content {
  
  

 
} */


@media  (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}




@media screen and (max-width: 460px) {
  


  .sidebar {
  
    width: 130px;

  }

  .sidebar.close ~ .home-section {
  
    left: 68px;
    width: 100%
  }


  .home-section {
    position: relative;
    left: 120px;
    width:100%;
  
  }


  .home-content {
 
     padding: 0px 0px 40px 16px;

   }


   .sidebar .nav-links li a .link_name {
    font-size: 9px;
 
  }

  .sidebar .logo-details .logo_name {
    font-size: 10px;
  }

  .sidebar .profile-details .job {
    font-size:10px;
  }

  .sidebar .profile-details {
    width: 58px;
    padding:6px;
  }


  .icon i {

    font-size: 9px;
  
  }
  
  .sidebar.close {
    width: 68px;
  }


  .sidebar .nav-links li i {
   
    font-size: 14px;
  
  }



}
@media only print {
    .noprint {
       visibility: hidden;
    }
    .inPrint * {
        visibility: visible;
        color:black;
     }
    .footer{
        position: fixed;
        bottom:12px;
        text-align: center;
        visibility: visible;
        background-color:rgba(200, 173, 20, 0.498);
        padding:6px;
        width: 102%;
        z-index: 1;
    
    }
 }


.customContainer{
border-radius: 12px;
padding:1% 3%;
margin:0px;
background-color:white;
font-size: 12px;

}


.leftLine{
    border-left: 2px solid rgba(219, 167, 47, 0.883);
}

.horizentalLine{
    width:100%;
    background:rgba(219, 167, 47, 0.883);
     height:3px;
}


.btn-print{
	/* border-radius: 26.5px; */
	background-color: #000000a2;
    border: 1px solid #000000;
	box-shadow: 0 16px 22px -17px #0d44b9;
	color: #fff;
	cursor: pointer;
border-radius: 3px;

	padding: 2px 40px;

	position: fixed;
    left:80%;
    top:22px;
}
.btn-print:hover {
	background-color: #000000;
	color: #fefefe;
    border: 1px solid #000000;


}



.btn-delete-invoice{
	/* border-radius: 26.5px; */
	background-color: #760606a2;
    border: 1px solid #760606a2;
	box-shadow: 0 16px 22px -17px #0d44b9;
	color: #fff;
	cursor: pointer;
border-radius: 3px;

	padding: 2px 10px;

	position: fixed;
    left:60%;
    top:22px;
}
.btn-delete-invoice:hover {
	background-color: #000000;
	color: #fefefe;
    border: 1px solid #000000;


}




.subHeadertext{

    font-size: 11px !important;
    color:#646262;
}

.top-text{

font-weight: 650;
font-size:14px;

}
.after-top-text{
font-size: 12px;
color:#646262;


}

.invoice-text{
    font-size: 26px;
    float:right;
    color: rgba(177, 130, 22, 0.883);
    font-weight:550;
    padding-top: 8px;

}


.bank-text{
    font-size: 18px;
    float:left;
    color: rgba(177, 130, 22, 0.883);
    font-weight:550;
    padding-top: 8px;

}

.titleInPrint{
    font-weight: 600;
}

.date-text{
    padding-top: 20px;

}

.horizentalLine2{
    width:100%;
    background:rgba(219, 167, 47, 0.883);
     height:1px;
}

.cent{
    text-align: center;
  

}

.text-descripiton{
    color: rgba(177, 130, 22, 0.883);
    font-weight:550;
    padding-top: 8px;
    font-size: 16px;
}

.text-bill{
    font-size:13px;
    font-weight:600;
}


.footer-note{

    font-size:10px;
}

.text-right{
    text-align: right;

}
@media screen{
.inPrint{
    display: none;
}
}




@media screen and (max-width: 470px) {

	
.btn-delete-invoice{



	padding: 2px 4px;

    left:60%;
    top:72px;
    font-size: 10px;
}


.btn-print{
	

	padding: 2px 10px;

font-size: 10px;
    left:10%;
    top:72px;
}

.invoice-logo{
    width:80px !important;
    height:70px !important;
}

.invoice-info-action{
    padding-left: 22px !important;
}

}

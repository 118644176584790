
.searchOuterLayer{

  width:100%
}


.searchBox {


  background: rgb(255, 255, 255);
  height: 45px;
    border-radius: 10px;
    padding: 10px;
    z-index: 99999;
  
  }

  
  
  .searchInput::placeholder {
    color: rgba(20, 116, 205, 0.851);
    opacity: 1; /* Firefox */
  }
  
  .searchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(237, 237, 237, 0.586);
  }
  
  .searchInput::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(237, 237, 237, 0.586);
  }





  .searchBox > .searchInput {
    width: 90%;
  }
  
  .searchBox > .searchButton {
  background: rgb(0, 0, 0);
  color : rgb(255, 255, 255);
  }
  
  .searchButton {
    color: rgb(17, 150, 212);
    float: right;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #2f3640;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
  }
  
  .searchInput {
    border:none;
    background: none;
    outline:none;
    float:left;
    padding: 0;
    color: rgb(0, 0, 0);
    font-size: 16px;
    line-height: 25px;
    width: 0px;
  
  }
  
  @media only screen and (max-width: 1100px) {
  .searchBox > .searchInput {
  
    width: 82%;
    padding: 0 0px;
  }
  }

  .selectBox {
  
    background: rgb(255, 255, 255);
    height: 47px;
    border-radius:4px;
    padding: 10px;
    z-index: 99999;
  
  }
  
  .searchIn {
    border:none;
    background: white;
    outline:none;
    padding: 0;
    color: rgb(0, 0, 0);
    font-size: 16px;
    transition: 0.4s;
    line-height: 25px;
    width:100%;
  
  }

  @media only screen and (max-width: 900px) {
    .selectBox > .searchIn {
    
      /* width: 170px; */
      padding: 0 6px;
    }





    }

    @media only screen and (max-width: 760px) {

    .searchOuterLayer{

      /* position: absolute;
     
      right:18px */
      width: 300px !important;
      margin-left: 12px;

    }




    /* .form-control {
      min-width: 0;
      width: 109px !important;
      color:red;
  } */

  }



        @media only screen and (max-width: 460px) {

       
      
  .outLineOfSearch{
    width: 240px !important;
    padding-left: 12px !important;
        }

      
     

        }
.bg-nav-body{
  background-color: rgba(20, 129, 212, 0.195);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

}
.menu-link{
  color:white !important
}
.uploaded-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.image-container img {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.image-name {
  font-size: 9px !important;
  margin-top: 5px;
  text-align: center;
}

.auction-card {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    /* background: linear-gradient(145deg, #252525, #ffffff2f); */
    /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
    border: none !important;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  .auction-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  /* Card Image */
  .auction-card img {
    height: 220px;
    object-fit: cover;
    width: 100%;
    /* border-radius: 15px 15px 0 0; */
    transition: opacity 0.3s ease;
  }

  .auction-card:hover img {
    opacity: 0.85;
  }

  /* Card Body */
  .auction-card-body {
    padding: 25px;
    background-color: rgba(224, 223, 223, 0.203);
    border-radius: 0 0 15px 15px;
  }

  .card-title {
    font-size: 0.9rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px !important;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .card-text {
    color: #777;
    font-size: 0.8rem;
    padding: 0px;
    font-weight: 500;
  }

  .card-text-label {
    color: #ff6600;
    font-size: 0.8rem;
    padding: 0px;
    font-weight: bold !important;
    
  }
  
  .auction-price {
    font-size: 1.1rem;
    font-weight: bold;
    color: #ff6600;
  }

  .auction-button {
    background-color: #054664;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 8px;
    width: 100%;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }

  .auction-button:hover {
    background-color: #054664;
  }

  /* Icon Bar Styling */
  .icon-bar-inventory {
    position: absolute;
    top: 50%;
    right: -60px;
    transform: translateY(-50%);
    width: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, right 0.3s ease;
    padding: 10px; /* Optional: Add padding to create spacing around icons */
    box-sizing: border-box; /* Ensures padding is included in the width/height calculations */
}

  .icon-bar-inventory a {
    color: white;
    font-size: 0.9rem;
    transition: transform 0.3s ease;
  }

  .icon-bar-inventory a:hover {
    color: rgb(238, 191, 38);
    transform: scale(1.2);
  }

  /* Show icon bar on hover */
  .auction-card:hover .icon-bar-inventory {
    opacity: 1;
    visibility: visible;
    right: 0;
  }





  .qr-code {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Full-screen dark shade */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    gap: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    
  }
  
  .qr-code .view-info {
    color: white;
    font-size: 1.3rem;
    transition: transform 0.3s ease;
    cursor: pointer;
    text-align: center; /* Ensure text aligns properly in the center */
  }
  
  .qr-code.show {
    opacity: 1;
    visibility: visible;
  }

  .icon-bar-inventory a{
    color:white !important;
  }

  .icon-bar-inventory a:hover{
    color:#ff6600 !important;
    cursor: pointer;
  }


  /* Badge Status Styling */
  .badge-status {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #093c57;
    color: white;
    font-size: 0.9rem;
    padding: 6px 12px;
    border-radius: 20px;
    text-transform: uppercase;
  }
.logo-inventory-background{
  max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            position: absolute;
            opacity: 0.3;
}


  @media only screen and (max-width: 922px) {
    /* Your mobile-specific styles here */
    /* .auction-card .icon-bar-inventory.show{
      opacity: 1;
      visibility: visible;
      right: 0;
    } */
  /* .icon-bar-inventory{
    background-color: rgba(0, 0, 0, 0.172);
  
  } */
   
  /* .icon-bar-inventory a{
   color: rgb(255, 255, 255) !important;
 
  
  } */
   .screen-lg{
    visibility: hidden;
   }
  }
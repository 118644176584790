/*
*
* ==========================================
* tabs
* ==========================================
*
*/

/* Rounded tabs */

@media (min-width: 576px) {
  .rounded-nav {
    border-radius: 50rem !important;
  }
}

@media (min-width: 576px) {
  .rounded-nav .nav-link {
    border-radius: 50rem !important;
  }
}


.validation-error{

  font-size: 12px;
  padding:7px 3px 3px 0px;
  color:red;
  transition: linear 0.2s;
 
}

/* With arrow tabs */

.with-arrow .nav-link.tabActive {
  position: relative;
}

.with-arrow .nav-link.tabActive::after {
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid rgb(232, 202, 10);
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

/* lined tabs */

.lined .nav-link {
  background: none !important;
  border: none;
  border-bottom: 3px solid transparent !important;
}

.lined .nav-link:hover {
  border: none !important;
  border-bottom: 3px solid transparent !important;
}

.lined .nav-link.tabActive {
  background: none !important;
  color: rgb(255, 255, 255) !important;
  border-bottom: 3px solid transparent !important;

  border-color: rgb(232, 202, 10) !important;
}

/*
*
* ==========================================
* FOR DEMO PURPOSE
* ==========================================
*
*/

.nav-pills .nav-link {
  color: white !important;
}
.text-uppercase {
  letter-spacing: 0.1em !important;
}

.nav-tabs {
  position: sticky;
  top: 10px;

  /* transition:0.1s; */
  transition:1s;

}
.nav-tabs-fix{
  background:#094274;
  top: 0px;
  position:fixed;
  padding-left:20px;
   padding-bottom:10px;
   width:80vw;
   transition:0.5s;
  z-index:2;

}

.tab-content {
  /* overflow: scroll; */
  width: 80vw;
  /* border: 1px solid red; */
  color: white;
  padding: 25px 5px 25px 5px;


}

/* .swal2-popup {
  font-size: 12px !important;
} */

.icons-link {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-radius: 50%;
  transition: all 0.3s;
  font-size: 0.9rem;
  list-style: none;
  text-decoration: none;
}

.icons-link:hover, .icons-link:focus {
  background: #ddd;
  text-decoration: none;
  color: #555;
}

.list-card h6{
color:black;
}


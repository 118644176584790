
 .circle3 {
  background: #97800b;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color:rgb(245, 245, 245);
  display: inline-block;
  font-weight: 600;
  line-height: 16px;
  margin-right: 5px;
  text-align: center;
  width: 16px;
  /* box-shadow: 4; */
  
}

.header-text-righ-bar{
  color:white;
   font-size:13px;
   text-align:center; 
  font-weight:600;
}

.sidebarRight {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 180px;
    background: #094274;
    z-index: 100;
    transition: all 0.5s ease;
padding-bottom: 40px;
padding-top: 80px;

overflow-y: scroll;

  }

.outerLayer{
padding:5px 0px 0px 0px;
border-top-right-radius: 20px;
border-bottom-left-radius: 20px;

border:2px solid rgb(45, 145, 239);
}
  #related_links {
   
    width: 100%;
    padding: 0px 0 0 0;
    margin-bottom: 1em;
    font-family: 'Trebuchet MS', 'Lucida Grande',
    Verdana, Lucida, Geneva, Helvetica, 
    Arial, sans-serif;
    color: #333;
    cursor: pointer;
    }

#related_links ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: none;
    }
    
#related_links li {
    margin: 0;
    }

#related_links li a {
    display: block;
    padding: 2px 5px 5px 0.5em;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
    width: 100%;
    text-align: center;
    }

    #related_links li a span {
        color:rgb(232, 193, 22);
      padding-left: 14px;
      font-size: 12px;
      font-weight: 550;
        }



/* html>body #related_links li a {
    width: auto;
    } */

#related_links li a:hover {
    border-left: 2px solid #d4c116;
    background:rgba(22, 117, 225, 0.188);
    color: rgb(255, 255, 255);
    }



    .header-text-righ-bar{
      color:white;
       font-size:10px;
       text-align:center; 
      font-weight:600;
    }


    @media screen and (max-width: 530px) {
      .sidebarRight {
      
        width: 100px;
    
      }

      #related_links li a {
      
        font-size: 9px;
    
        }

        #related_links li a span {
        padding-left: 4px;
        font-size: 9px;
        font-weight: 550;
          }
  





    }
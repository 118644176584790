

/* Container for the carousel */
.carouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 20px;
  overflow: hidden; 

}

/* Wrapper for carousel components */
.carouselWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

/* Main Image Wrapper */
.mainImageWrapper {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin-bottom: 10px;
}

/* Style for the main image */
.mainImage {
  width: 100%;
  height: auto;
  cursor: zoom-in;
  transition: transform 0.3s ease;
}

/* Left navigation button */
.navButtonLeft {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Right navigation button */
.navButtonRight {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Side Zoom View */
.sideZoomViewWrapper {
  position: absolute;
  top: 5%;
  right: 21%;
  width: 250px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  pointer-events: none; /* Non-interactive with the cursor */
}

/* Style for the zoomed image in side view */
.sideZoomView {
  width: 100%;
  height: 100%;
  background-size: 500%;
  background-repeat: no-repeat;
  background-position: center;
  border: 2px solid rgb(2, 2, 2); /* Optional: Adds a border for better visibility */
}

/* Thumbnail navigation */
.thumbnailWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
  padding: 10px 0;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
  cursor: grab;
}

/* Style for each thumbnail */
.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  transition: border 0.3s ease;
}

/* Media Queries for Responsiveness */

/* Mobile View */
@media (max-width: 768px) {
  .sideZoomViewWrapper {
    top: -5%; /* Adjust the zoom view positioning for mobile */
     /* Slightly adjust positioning to better fit on the screen */
    width: 300px;  /* Adjust size of the zoom view for smaller screens */
    height: 290px;
  }

  .thumbnailWrapper {
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    padding: 5px 0;
  }
}

/* Extra Small Mobile View */
@media (max-width: 480px) {
  .navButtonLeft,
  .navButtonRight {
    padding: 5px;
  }

  .closeZoomButton {
    padding: 5px 10px;
  }

  .thumbnail {
    width: 50px;
    height: 50px;
  }
}

.badges-indicators{

position:absolute;
left: 95%;
right: 95%;
overflow: hidden;
bottom:10px;
}




.New {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    background: #0eb0d0;
    color: #fff;
    padding: 1px 14px;
    border-radius: 3%;
    font-size: 20px;
}

.All {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    background: #a8a011;
    color: #fff;
    padding: 1px 14px;
    border-radius: 3%;
    font-size: 20px;
}


.Loaded {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    background: #62a609;
    color: #fff;
    padding: 1px 14px;
    border-radius: 3%;
    font-size: 20px;
}

.Dispatched {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    background: #a8a011;
    color: #fff;
    padding: 1px 14px;
    border-radius: 3%;
    font-size: 20px;
}

.Terminal{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    background: #08588d;
    color: #fff;
    padding: 1px 14px;
    border-radius: 3%;
    font-size: 20px;
}





@media screen and (max-width: 470px) {
  

  
	.badges-indicators{

    right:93%;
    left:93%;
    bottom:10px;
    }



    .New {
        
        font-size: 16px;
        border-radius: 50px;

    }
    
    .All {

        font-size: 16px;
        border-radius: 50px;
    }
    
    
    .Loaded {
        font-size: 16px;
        border-radius: 50px;

    }
    
    .Dispatched {

        font-size: 16px;
        border-radius: 50px;

    }
    
    .Terminal{
        font-size: 16px;
        border-radius: 50px;

    }
    
    
    




  }

.delete-icon-autos{
	position:absolute;
	top:1px;
	right:8px;
	padding:4px 0px;
}
.delete-icon-autos i{
	font-size:12px;
	color:rgb(134, 9, 9);
	cursor:pointer;
}
.btn-delete-loading{
	/* border-radius: 26.5px; */
	background-color: #a62626;
    border: 1px solid #a62626;
	box-shadow: 0 16px 22px -17px #a62626;
	color: #fff;
	cursor: pointer;


	padding: 1% 19px;

	font-size:10px
}
.btn-delete-loading:hover {
	background-color: #d13131;
	color: #fefefe;


}


.btn-container{
	/* border-radius: 26.5px; */
	background-color: #11528f;
    border: 1px solid #11528f;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;


	padding: 2% 10px;

	font-size:10px
}
.btn-container:hover {
	background-color: #03153B;
	color: #fefefe;


}



.btn-edit-loading{
	/* border-radius: 26.5px; */
	background-color: #11528f;
    border: 1px solid #11528f;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;


	padding: 1% 25px;

	font-size:10px
}
.btn-edit-loading:hover {
	background-color: #000000;
	color: #fefefe;


}




.btn-cont-loading{
	/* border-radius: 26.5px; */
	background-color: #11528f;
    border: 1px solid #11528f;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;


	padding: 1% 9px;

	font-size:10px
}
.btn-cont-loading:hover {
	background-color: #000000;
	color: #fefefe;


}


.btn-confirm-loading{
	/* border-radius: 26.5px; */
	background-color: #11528f;
    border: 1px solid #11528f;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;


	padding: 1% 14px;

	font-size:10px
}
.btn-confirm-loading:hover {
	background-color: #136806;
	color: #fefefe;


}



.btn-reject-loading{
	/* border-radius: 26.5px; */
	background-color: #11528f;
    border: 1px solid #11528f;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;


	padding: 1% 19px;

	font-size:10px
}
.btn-reject-loading:hover {
	background-color: #000000;
	color: #fefefe;


}



.floating-btn-left {
	border-radius: 26.5px;
	background-color: #13202c;
    border: 1px solid #13202c;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	position: fixed;
	bottom: 10px;
	left: 80%;
	z-index: 999;
}

.floating-btn-left:hover {
	background-color: #000000;
	color: #fefefe;


}
/* for moving the button on scroll */



.floating-btn-move {
	border-radius: 26.5px;
	background-color: #13202c;
    border: 1px solid #13202c;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	position: fixed;
	top: 60px;
	right: 3%;
	z-index: 999;
    transition:linear .1s;
}

.floating-btn-move:hover {
	background-color: #000000;
	color: #fefefe;


}
/* ends here */


.floating-btn-right {
	border-radius: 26.5px;
	background-color: #13202c;
    border: 1px solid #13202c;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	position: fixed;
	top: 20px;
	right: 3%;
	z-index: 999;
}

.floating-btn-right:hover {
	background-color: #000000;
	color: #fefefe;


}

.floating-btn-notification {
	border-radius: 4.5px;
	background-color: #7c0907;
    border: 1px solid #7c0907;
	box-shadow: 0 16px 22px -17px #000000;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 2px 20px;
    z-index: 999;
}

.floating-btn-notification:hover {
	background-color: #9c0c0c;
	color: #fefefe;


}

@media screen and (max-width: 795px) {
	.floating-btn-notification-movment {
		position:absolute;

	}
	
}

.selector div span{
color:white;
}

.headerTable{


padding-top: 5px;

}

.tableHead{
    
background-color:rgb(8, 46, 84);
color:white;

}

.res thead th{
    padding:7px 2px 7px 2px;
        font-weight:normal;
        font-size: 14px;
    text-align:center;
    }
    
 .res{
border-collapse: separate;
border-spacing: 0 15px;
 }
 


.trBack{
 background-color:rgb(255, 255, 255);
border-radius: 12px;
text-align: center;

}


.rest-table tr td{
 
    padding:0px;
 
}


.trBack td span{
 
text-align:center;
font-size:12px;
font-weight:400;
}

.trBack td img{

    width: 70px;


    }

.t{
	font-size: 12px;
	color:rgb(12, 84, 114);
	text-align: center;

}



.col img{

    width: 70px;


    }
.crtop{
		background-color:rgb(51, 94, 163) !important;
		color:white;
	}

.ht{
font-size: 12px;
text-align: center;
	}


	.totalCount{
		font-size: 13px;
		color: rgb(255, 219, 12);
	}




.notification {
	background-color: rgb(11, 94, 122);
	color: white;
	text-decoration: none;
	padding: 4px 10px;
	position: relative;
	display: inline-block;
	border-radius: 4px;
	border-color: rgb(9, 103, 134);
	border:none;
  }
  
  .notification:hover {
	background: rgb(19, 19, 20);
  }
  
  .notification .badge {
	position: absolute;
	top: -10px;
	right: -10px;
	padding: 4px 8px;
	border-radius: 50%;
	font-size:11px;
	background-color: rgb(7, 47, 80);
	color:white;
  }

  .check{
	position: absolute;
	left:-4px;
	top:-6px;
	z-index:99;

  }

  .headerButton{
position:fixed;
top:10px;
right:110px;
  }



   .btnDelet{
	border-radius: 6px;
	background-color: #0a365e;
    border: none;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	z-index: 999;
  }


  .btnDelet:hover{
	background-color: #000000;
	color: #fefefe;
  }

  .createdBy{
	position:absolute;
	 bottom:4px;
	 left:-90px;
	 background-color:rgba(17, 72, 192, 0.197);
	 padding:0px 7px 0px 7px;
	 border-radius:6px;
	 color:rgb(0, 0, 0);
	


  }


  @media screen and (min-width: 1200px) {
	.createdBy{
		left:-130px;
	}
  }

/* it is for ready list  */
  .createdByReady{
	position:absolute;
	bottom:4px;
	left:-90px;
	background-color:rgba(17, 72, 192, 0.197);
	padding:0px 7px 0px 7px;
	border-radius:6px;
	color:rgb(0, 0, 0);
   
  }



  @media screen and (min-width: 1200px) {
	 .createdByReady{
		left:-100px;
	}
  }






  @media screen and (max-width: 470px) {
  

	

.floating-btn-left {

	font-size: 10px;
	padding: 1px 5px;
	bottom: 15px;
	left: 73%;

}



.floating-btn-right {

	font-size: 10px;
	
	padding: 4px 8px;
	top: 30px;
	right: 10%;
}
/* .tab-content{
	padding:0px !important;
} */

.main-container{
	margin-left: -50px !important;
}

}
.btn-delete{
    text-decoration:none;
    color:white;
    cursor:pointer;
    padding:5px 10px 5px 10px;
    background-color:rgb(193, 19, 19);
    border-radius: 12px;
}
.btn-delete:hover{
    color:white;
    background-color:rgb(135, 12, 12);
}

.img-wrap {
    position: relative;
    display: inline-block;
    border-radius: 2%;
    overflow: hidden;

    /* border: 1px rgb(0, 0, 0) solid; */
    font-size: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.img-wrap img{
    border-radius: 2%;
    width: 100%;
    height: 100%;
}
.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    background-color: rgb(183, 40, 40);
    padding: 5px 6px 5px 6px;
    color: rgb(255, 255, 255);
    font-weight:499;
    cursor: pointer;
    visibility: hidden;
    text-align: center;
    font-size: 22px;
    line-height: 0px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

}
.img-wrap:hover .close  {
    visibility:visible;


}

.close i{

    font-size:14px;
}


.img-wrap:hover .shade {
   
position: absolute;
width: 100%;
height: 100%;
    z-index: 9;
    background-image: linear-gradient(
      
      rgba(9, 68, 132, 0),
      rgba(0, 0, 0, 0.081),
      rgba(0, 0, 0, 0.309)
    );

    transition: opacity 0.3s;
  }
  
  .img-wrap:hover .checkmark{
    visibility:visible;
  }
  
  .header {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
    color:rgb(4, 16, 35);
   border-radius: 3%;
    background:rgba(175, 175, 206, 0.244);
  }
  
  
  .newCheckbox{
    position: absolute;
    z-index: 999;
    top: 3px;
  }





  .contain {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    
  }
  
  /* Hide the browser's default checkbox */
  .contain input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    visibility:hidden;
    background-color: rgba(26, 112, 232, 0.538);
    box-shadow: rgba(0, 0, 0, 0.093) 1.95px 1.95px 2.6px;

  border-radius: 15px;
  
  }
  
  /* On mouse-over, add a grey background color */
  .contain input ~ .checkmark:after{ 
    display: block;
transition: .1s ease-out;
  }
  
  /* When the checkbox is checked, add a blue background */
  .contain input:checked ~ .checkmark {
    background-color: #1a6de2;
    visibility: visible;

  }
  

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  
  /* Show the checkmark when checked */
  .contain input:checked ~ .checkmark:after{
    display: block;
    visibility: visible;
  }
  
  /* Style the checkmark/indicator */
  .contain .checkmark:after {
    left: 8px;
    top: 8px;
    width: 8px;
    height: 12px;
    border: solid rgb(255, 255, 255);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .zoom:hover {
    transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
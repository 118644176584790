.circle {
    background: #0000009d;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fcf1f1;
    display: inline-block;
    font-size:14px;
    font-weight: 600;
    line-height: 30px;
    margin-right: 5px;
    text-align: center;
    width: 30px;
    box-shadow: 4;
  }

  .circle2 {
    background: #ffffff9d;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fcf1f1;
    display: inline-block;
    font-weight: 600;
    line-height: 30px;
    margin-right: 5px;
    text-align: center;
    width: 30px;
    box-shadow: 4;
  }
  

  .circle3 {
    background: #ffc001;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #000000;
    display: inline-block;
    font-weight: 600;
    line-height: 15px;
    margin-right: 5px;
    text-align: center;
    width: 12px;
    height: 12px;
    box-shadow: 4;
    z-index:999;
    position:absolute;
    font-size: 12px;
    left:37px;
    top:12px;
    /* border: 1px solid rgb(85, 67, 2); */
    /* transform: translate(-50%, -50%); */

  }
  


.circle3:before,
.circle3:after {
  content: '';
  display: block;
  position: absolute;
  top: 0; 
  right: 0; 
  bottom: 0; 
  left: 0;
  border: 1px solid #2a2307;
  border-radius: 50%;
}


.circle3:before {
  animation: ripple 2s linear infinite;
}

.circle3:after {
  animation: ripple 2s linear 1s infinite;
}

@keyframes ripple{
  0% { transform: scale(1); }
  50% { transform: scale(1.3); opacity:1; }
  100% { transform: scale(1.6); opacity:0; }
}

 /* .carousel .slide {
    background: #1071c650!important;

} */

.carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid rgb(195, 176, 5);

}

.carousel .thumb img {
    width: 70px !important;
    height: 60px !important;
}

.carousel .slide img {
    max-height: 550px;  /* change this to whatever you want */
    width: auto;
}

.no-images-found{
	width: 80%;
	max-height: 70%;
	padding:12px 12px 12px 99px;
}
.card-custom-hover-auto-view{
    background:rgba(128, 128, 128, 0.291) !important;
	color:rgb(240, 240, 240) !important;
	border-left: 4px solid rgb(22, 130, 225) !important;


}
.header-title{
	font-size: 18px;

	color: white;
	padding: 1px;
}

.info-title{
	font-size: 12px;
	font-weight: 500;
	color: white;
	padding: 1px;
}
.info{
	font-size: 11px;
	color: rgb(250, 199, 88);
	padding: 1px;
}



.deletion-button-place{
	position: absolute;
	top:8px;
	right:60px;
}

.list-container-header {
    position: relative; /* Enables flexbox layout */
    top: 10px;
    color: rgb(255, 255, 255);
    text-align: left; /* Change to left-align text */
    background-color: #000000;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 13px;
    box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
    width: 100%;
    gap: 10px; /* Adds spacing between flex items */
	


}

.list-container-header span{
	padding-right: 4px;
}

.checkbox-auto {
    position: relative; /* Makes checkbox and content align */
	padding: 6px;
	top:2px;
}


@media only screen and (max-width: 1100px) {

	.deletion-button-place{
		top:70px;
	right:60px;
	}


}

/* @media only screen and (max-width: 1501px) {

	.checkbox-auto{
		top:3px;
		left:-34px;


	}

} */


.lineInPermissions {
	width: 100%; 
	text-align: center; 
	border-bottom: 1px dashed rgb(0, 0, 0); 
	line-height: 0.1em;
	margin: 11px 0 2px; 
 } 
 
.lineInPermissions span { 
	 background:rgb(0, 0, 0); 
	 padding:0 9px; 
	 color:white;
	 font-size: 11px;
	 border-radius: 12px;

 }

.add-images-btn{
	border:none;
	color:white;
	background:rgb(12, 93, 154);
	padding:2px 6px;
	border-radius: 2px;
	text-decoration: none;
	transition: linear .1s;
	white-space: nowrap;
	cursor:pointer;

}

.add-images-btn:hover{
	background:black;
	color:white;
}



.shippingInvoiceText{
color:rgb(0, 89, 255);
}



.delete-all-autos{
	/* border-radius: 26.5px; */
	background-color: #850d0d;
    border: 1px solid #850d0d;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
border-radius: 3px;

	padding: 8% 17px;

	font-size:11px
}
.delete-all-autos:hover {
	background-color: #03153B;
	color: #fefefe;
    border: 1px solid #03153B;


}



.deleteSelectedOuterLayer{
	position:absolute;
	top:8px;
	right:80px;
	z-index:999;
}


@media only screen and (max-width: 1300px) {

	.deleteSelectedOuterLayer{
		top:68px;
		right:70px !important;
	}
}

.created_by{
	background-color:rgba(4, 68, 113, 0.168);
	padding:1px 3px;
	border-radius: 4px;
	font-size: 11px;
	font-weight: 550;
}
.for_whom{
	background-color:rgba(0, 0, 0, 0.168);
	padding:1px 3px;
	border-radius: 4px;
	font-size: 11px;
	font-weight: 550;
	color:rgb(38, 38, 148)
}

.verticle-gap{
	padding-top: 50px;
}
.autoListSearchHeaderSticky {
	position: relative;
	/* top: 30px; */
  padding-top: 30px;
  
  }
  .autoListSearchHeaderFixed{
	padding-top: 6px;
	padding-bottom: 10px;

	background:#094274;
	top: 0px;
	position:fixed;

	 width:87%;

	 z-index:2;
	 animation: fadeIn 0.6s;

  }
  
  .notificationSearchHeaderSticky {
	position: relative;
	/* top: 30px; */
  padding-top: 30px;
  
  }
  .notificationSearchHeaderFixed{
	padding-top: 6px;
	padding-bottom: 10px;

	background:#094274;
	top: 0px;
	position:fixed;

	 width:100%;

	 z-index:8;
	 animation: fadeIn 0.6s;

  }
  

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


  .searchOutline{
	width:70%;

}




/* * {
	box-sizing: border-box;
} */
/* ===========================
autoview css
=========================== */

.autoViewHeader{
background:white;
position:absolute;
top:0;
left:30px;
margin: 0 auto;

width: auto;
padding:6px;
overflow-x: hidden;

}
.cardHeader{
color:white;
background:rgb(10, 90, 140);
border-bottom:1px solid rgb(187, 196, 28);
border-left:1px solid rgb(187, 196, 28);

border-top-left-radius: 50px;
border-bottom-right-radius: 50px;

}
.sub-header{
	font-size: 13px;
}

.cardHeader h6{
	text-align: center;
	font-size:29px
}

.side-card table tr td{
font-size: 14px;
padding:3px
}

.side-card table tr td:nth-child(1){
/* background-color:rgba(6, 40, 71, 0.121); */
color:rgb(22, 96, 165);
font-weight:600;
}
.side-card table thead tr th{
	background-color:rgb(11, 111, 157);
	color:white;
	font-weight:550;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;

}

.side-card table thead tr th span{
padding-left:38px;

}
.side-card table{
	border:0px;

}
.side-card{
	border:0px;
}

.downloadImages{
	position:absolute;
	top:100px;
	left:108px;

	z-index:99;
	color:white;
	width:195px;
	
	text-align:center;
	padding:3px;
	cursor:pointer;
	background-color:rgb(26, 132, 225);
	box-shadow: 0px 0px   rgb(26, 89, 199);

	

}
.downloadImages:hover{
	background-color:rgb(13, 62, 106);

}

@media only screen and (max-width: 1300px) {
	

	.downloadImages{
	
    top:100px;
	left:46px;
	
	}


  }

 

@media only screen and (max-width: 500px) {
	

	.downloadImages{
	
    top:130px;
	left:108px;
	
	}


  }
/* ===========================
autoview css ends here
=========================== */




/* =================
the main class is for auto list contianers size;
============= */
.main{
	/* padding: 0px 180px 12px 3px; */
	/* width: auto;
	overflow-x: hidden; */

}


.modal {
	overflow-y:auto;
  }

.dragDrop{
	width:100%;
	padding:18px;
	height:60px;
	border: 1px dashed rgb(10, 80, 134);
	/* border-right: 2px dashed #1243ab; */

	box-shadow: 0px 0px   rgb(26, 89, 199);
	border-radius:6px;
	background:transparent;
	background: url("../../components/assets/pngegg.png");
	background-size: 50px 40px;
	background-repeat: no-repeat;
	background-position: center; /* Center the image */


}
.dragDrop span{
	color:rgba(128, 128, 128, 0.602);

}

/* .dragDrop:hover{
	background:rgba(194, 190, 190, 0.259);
	background-image: url("../components/assets/GA-01.png");
	background-size: 50px 40px;
	background-repeat: no-repeat;
	background-position: center; 
} */

.dragDrop:hover {


  box-shadow:0 8px 20px 0px rgba(0,0,0,0.125);
	  
  }
  
  .dragDropSelected {

	width:100%;
	padding:18px;
	height:60px;
	border: 1px dashed rgb(10, 80, 134);
	/* border-right: 2px dashed #1243ab; */

	box-shadow: 0px 0px   rgb(26, 89, 199);
	border-radius:6px;
	background:transparent;
	background: url("");
	background-size: 50px 40px;
	background-repeat: no-repeat;
	background-position: center; /* Center the image */

	  
  }


.btn-submit {
	background: rgb(10, 80, 134);
	border: 0;
	border-radius: 5px;
    
	/* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
	color: #fff;
	font-size: 14px;
	padding: 10px;

	letter-spacing: 1px;
}
.btn-submit:hover{
    background:#03102d;
}



.btn-cancel {
	background: rgb(79, 96, 109);
	border: 0;
	border-radius: 5px;
    
	/* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
	color: #fff;
	font-size: 14px;
	padding: 10px;

	letter-spacing: 1px;
}
.btn-cancel:hover{
    background:#03102d;
}



.picker-inputs-lebel{
	color:gray;
	font-size:14px;
	padding-left:2px;
}


.date-picker input {
	height:40px;
	border: 1px solid #dbdbdb;
	border-radius:4px;
	padding:12px;
	

}

/* .date-picker input:focus {
	box-shadow: 0 0 5px rgb(32, 110, 205);

	border: 1px solid rgb(12, 59, 72);
  } */

.modal-header-color{
background:rgb(10, 80, 134);

}




.modal-header-text{
	color:white;
}
.modal-body{
	background-color:rgba(11, 53, 88, 0.042)
}
.modal-footer{

	background-color:rgba(11, 53, 88, 0.042)

}

.custom-border{
	border-right: 1px solid rgba(10, 80, 134, 0.579);
}

.title{
font-size:10px;
font-weight:600;
color:rgb(2, 31, 126);
}

.description{
	font-size:12px;
}

.course {
	background-color: rgb(255, 255, 255);
	border-radius: 10px;
	/* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
	display: flex;
	max-width: 100%;
	margin: 10px;
	overflow-x:hidden;
    font-size:12px;
	box-shadow:0 8px 20px 0px rgba(0,0,0,0.125);

}



.list-image-container{
	min-width:99%;
	height:100%;

	/* border:solid 1px red; */
	/* border-radius:4px 0px 0px 4px; */
	


}

.list-image-container span img{
	width: 100%;
	padding-bottom: 5px;
	padding-right: 2px;

	
	/* border-radius:30px 40px 0px 4px; */
	
	/* z-index: 11; */
	
	
	
	  }

/* .list-image:hover{



} */


.headerContainer{
	display: flex;
	justify-self: center;
	align-items: center;
	padding-left: 17px;
	padding-right: 40px;


}

.slider-container {
	position:relative;
	width: 70%;
	top: 20px;
	right:10px;
	left:10px;
	bottom:10px;

	
	
	/* Add this */

	
	}


/* for images modal  transparent color*/ 
	.special_modal .modal-content{
		background-color: rgba(0, 0, 0, 0.771);
	
	    border: 0px solid;
		/* background-color: transparent; */
	

	 }

	
.closeButton{

	position:'absolute';
	right:'5%';
	 top:'1%';
	 z-index:'99999';
	 background-color:'white';
}

/* for images modal  transparent color*/ 

/* 
.list-container-header{

	position: relative;
	top:10px;
	color:rgb(255, 255, 255);
	
	text-align: center;
	background-color:#000000;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	font-size: 13px;
	box-shadow:0 8px 20px 0px rgba(0,0,0,0.125);

	width:100%;

} */

/* 
.list-container-header span{
	display:inline-block;
padding:0px 2px 0px 2px;
} */



.course:hover{
	/* background-color: rgba(30, 155, 250, 0.931); */
	box-shadow:0 8px 20px 0px rgba(0,0,0,0.125);
	cursor: pointer;
	

}


/* .icons-corner{
    display:flex;
    justify-content: center;
    align-items: center;
background:rgb(154, 15, 15);
width:40px;


} */




.icon-bar {
    width: 40px;
	background-color:#000000;

  }
  
  .icon-bar a {
    display: block;
    text-align: center;
    padding-top: 11px;
	padding-bottom:11px;

    transition: all 0.3s ease;
    color: white;
    font-size: 14px;
    /* border-left:solid 4px rgb(9, 56, 113); */
  }

  .icon-bar a i {

    color: white;

  }
  
  .icon-bar a:hover {
    background-color: rgb(155, 119, 12);
    color: white;
  }
  
  /* .active {
    background-color: #04AA6D;
  } */

.course h6 {
    font-size:16px;
   display:block;
	opacity: 0.6;
	margin: 0;
	letter-spacing: 1px;
	text-transform: uppercase;
    word-break: break-all;

}

.course h2 {
    font-size:12px;
	letter-spacing: 1px;
	margin: 10px 0;
    word-break: break-all;

}
.course h3 {
    font-size:12px;
	letter-spacing: 1px;
	margin: 10px 0;
    color: white;
    word-break: break-all;

}

.course-preview {
	background-color: #0a2f4e;
	color: #fff;
	padding: 10px;
	max-width: 280px;
    width:280px;
}

.course-preview a {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	opacity: 0.6;
	margin-top: 10px;
	text-decoration: none;
}

.course-info {
	padding: 10px;
	position: relative;
	width: 100%;
}

.course-info .col-md-4{
	display: inline-block;
}


.progress-text {
	font-size: 10px;
	opacity: 0.6;
	letter-spacing: 1px;
}



.btn-list {
	background: #0b436f;
	border: 0;
	border-radius: 50px;
    
	/* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
	color: #fff;
	font-size: 13px;
	padding: 3px 20px;
	position: absolute;
	bottom: 30px;
	right: 30px;
	letter-spacing: 1px;
}
.btn-list:hover{
    background:#03102d;
}
.floating-btn {
	border-radius: 26.5px;
	background-color: #13202c;
    border: 1px solid #13202c;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 999;
}

.floating-btn:hover {
	background-color: #000000;
	color: #fefefe;


}

.floating-btn-delete:focus {
	outline: none;
}


.floating-btn-delete {
	border-radius: 26.5px;
	background-color: #13202c;
    border: 1px solid #13202c;
	box-shadow: 0 16px 22px -17px #03153B;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	padding: 12px 20px;
	position: fixed;
	top: 20px;
	right: 300px;
	z-index: 999;
}

.floating-btn-delete:hover {
	background-color: #000000;
	color: #fefefe;


}

.floating-btn:focus {
	outline: none;
}

.floating-text {
	background-color: #001F61;
	border-radius: 10px 10px 0 0;
	color: #fff;
	font-family: 'Muli';
	padding: 7px 15px;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	z-index: 998;
}

.floating-text a {
	color: #FF7500;
	text-decoration: none;
}

@media screen and (max-width: 480px) {

	.social-panel-container.visible {
		transform: translateX(0px);
	}
	
	.floating-btn {
		right: 10px;
	}
}




@media screen and (max-width: 470px) {
  

  
	.main{
		/* padding: 0px 180px 12px 3px; */
		/* width: auto;
		overflow-x: scroll; */
	
	}
  


	.floating-btn {
		
		font-size: 10px;
		padding: 4px 10px;
		right: 7px;

	}



  }



  @media only screen and (max-width: 490px) {
	.carousel-images{
	  max-width: 100%;
	}


	.carousel-outer{
		width: 100%;
		height: 100%;
		
		/* Add this */
		position:fixed;
		top:15%;
		left:0;
		bottom:20%;
		
		}

		.carousel-outer-view-auto{
			width: 100%;
			height: 100%;
			position:relative;
			top:12px;
			left:0;
			bottom:20%;
			
		}

.downloadImages{
	position:absolute;
	top:12%;
    left:10%;
	font-size: 10px;
	width: 28%;
	
}

  }
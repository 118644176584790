/* body {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-color: #efefef;
    font-weight: 300; } */
  
  /* p {
    color: #b3b3b3;
    font-weight: 300; } */
  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  
  a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
    a, a:hover {
      text-decoration: none !important; }
  
  .content {
    padding: 0rem 0; }
  
  h2 {
    font-size: 20px; }
  
  .custom-table {
    min-width: 900px; }
    .custom-table thead tr, .custom-table thead th {
      border-top: none;
      border-bottom: none !important; 
      color:rgb(255, 255, 255) !important;
      font-weight: 350 !important;
      font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


    }
    .custom-table tbody th, .custom-table tbody td {
      color: #777;
      font-weight: 400;
      padding-bottom: 20px;
      padding-top: 20px;
      font-weight: 300; }
      .custom-table tbody th small, .custom-table tbody td small {
        color: #b3b3b3;
        font-weight: 300; }
    .custom-table tbody tr:not(.spacer) {
      border-radius: 7px;
      overflow: hidden;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
      .custom-table tbody tr:not(.spacer):hover {
        -webkit-box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.1); }
    .custom-table tbody tr th, .custom-table tbody tr td {
      background: #fff;
      border: none; }
      .custom-table tbody tr th:first-child, .custom-table tbody tr td:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px; }
      .custom-table tbody tr th:last-child, .custom-table tbody tr td:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px; }
    .custom-table tbody tr.spacer td {
      padding: 0 !important;
      height: 10px;
      border-radius: 0 !important;
      background: transparent !important; }
  
  /* Custom Checkbox */
  .control {
    display: block;
    position: relative;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 18px; }
  
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  
  .control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 2px solid #ccc;
    background: transparent; }
  
  .control--radio .control__indicator {
    border-radius: 50%; }
  
  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    border: 2px solid #007bff; }
  
  .control input:checked ~ .control__indicator {
    border: 2px solid #007bff;
    background: #007bff; }
  
  .control input:disabled ~ .control__indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
    border: 2px solid #ccc; }
  
  .control__indicator:after {
    font-family: 'icomoon';
    content: '\e5ca';
    position: absolute;
    display: none; }
  
  .control input:checked ~ .control__indicator:after {
    display: block;
    color: #fff; }
  
  .control--checkbox .control__indicator:after {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -52%);
    -ms-transform: translate(-50%, -52%);
    transform: translate(-50%, -52%); }
  
  .control--checkbox input:disabled ~ .control__indicator:after {
    border-color: #7b7b7b; }
  
  .control--checkbox input:disabled:checked ~ .control__indicator {
    background-color: #007bff;
    opacity: .2;
    border: 2px solid #007bff; }
  
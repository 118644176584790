.cont{
    padding-top:40px;
    padding-right:20px;
    padding-left:20px;
    padding-bottom: 20px;


}

.wrimagecard{	
	margin-top: 0;
    margin-bottom: 1.5rem;
    text-align: left;
    position: relative;
    background: #fff;
    box-shadow: 12px 15px 20px 0px rgba(46,61,73,0.15);
    border-radius: 4px;
    transition: all 0.3s ease;
}
.shade{
    width: 100%;
    height: 15%;
    position:absolute;
    bottom: 0;
    background:rgba(15, 125, 181, 0.803);
    visibility: hidden;
    border-radius: 2px;
 
    cursor: pointer;

}
.shade span{
    font-size:11px;
    padding:6px;
    color:white;
    text-align: center;
    white-space: nowrap;


}
.wrimagecard .fa{
	position: relative;
    font-size: 30px;
}
.wrimagecard .fas{
	position: relative;
    font-size: 30px;
}


.wrimagecard-topimage_header{
padding: 20px;
}
a.wrimagecard:hover .shade, .wrimagecard-topimage:hover .shade {
    box-shadow: 2px 4px 8px 0px rgba(57, 55, 55, 0.2);
    visibility: visible;
   

}
.wrimagecard-topimage_title:hover{
    color:rgb(25, 103, 145);

}
a .wrimagecard-topimage_header:hover{
    color:rgb(30, 140, 199);

}
.wrimagecard-topimage a {
    width: 100%;
    height: 100%;
    display: block;
}
.wrimagecard-topimage_title {
    padding: 20px 24px;
    height: 80px;
    padding-bottom: 0.75rem;
    position: relative;
}
.wrimagecard-topimage a {
    border-bottom: none;
    text-decoration: none;
    color: #525c65;
    transition: color 0.3s ease;
}

.container-Layer{
    padding: 40px 10px 40px 20px;


   
}

.container-second{
    padding-top:110px;
  
}

.custom-pos{
    position: relative;
}

.checkbox-container-tracker{
position:absolute;
right:33px;
top:35px
}

.buttonContainer-status-multiple{
    position:fixed;
    top:122px;
    right:22px;
    z-index:999
}
.trackList-icon{
    padding: 5px;
    color:rgb(237, 190, 19) !important;
}
.trackList-icon:hover{
    color:rgb(213, 213, 213) !important
}

.track-container{
    /* padding-left: 200px !important; */
    position: absolute;
    right:12px;
    top:50%;
}

.selector-container{
    position:fixed;
    top:0px;
    z-index:12;
	background:#094274;
    width: 78%;
padding-top: 12px;
   
}

.back-button-outer{
    position:fixed;
    top:13px;
    right:20px;
    background-color: rgb(26, 137, 207);
    width:27px;
    height:25px;
    border-radius: 50%;
    cursor: pointer;
}

.back-button-outer:hover{
    background-color: rgb(8, 34, 51);
    cursor: pointer;

}
@media only screen and (max-width: 800px) {
	

	.shade span{
font-size: 9px;
	
	}


  }

.addButton{
position:fixed;
top:13px;
}



.card-custom-hover{
    border-left: 4px solid rgb(222, 197, 57) !important;
    background:rgba(128, 128, 128, 0.291) !important;

}

.icon-container{
    padding-right: 14px;
    position: relative;
    display:inline-block;
    top:-6px;
    /* border:1px solid red; */

}
.icon-container i{
    font-size: 24px;
    color:rgb(222, 197, 57);

}

.body-info-container{
display: inline-block;
padding-right: 15px;
}
.date-text-t{
    font-size: 10px;
    color:rgba(218, 216, 216, 0.6);
}

.body-info{
    font-size: 11px;
    color: rgba(218, 215, 215, 0.787);
    /* text-align: justify; */
    /* text-justify: inter-word; */
    /* border:1px solid red; */

}

.search-settings-tracker{
    position: fixed;
    bottom:20px;
    right:10px;
    z-index:99
}

@media only screen and (min-width: 770px) {
    .search-settings-tracker{
        width: 60%;
    }
  }

  @media only screen and (max-width: 430px) {
    .search-settings-tracker{
        width: 40%;
        right:10px;
        bottom:5px
    }
  }
.checkbox-container{
    position: relative !important;
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    /* padding-bottom: 4px; */

    /* border:1px solid red; */
    padding-left: 40px;

    /* display: inline-block !important; */
}

.settings-title{
   
    color:rgb(255, 255, 255);
    width: 100%;

}
.card-custom-hover:hover{

cursor: pointer;
border-left: 4px solid rgb(22, 130, 225) !important;
 background:rgba(128, 128, 128, 0.222);

}

.card-custom-hover:hover i{

    cursor: pointer;
     color:rgb(3, 125, 232)
    
    }
.icon-container{

    width:auto
}


.settings-list-card{
    border-left: 4px solid rgb(240, 206, 17) !important;
    background:rgba(128, 128, 128, 0.222) !important;
    color:white !important;
    border-radius: 4px;
}

.settings-links i{
color: rgb(224, 200, 11) !important;
}

.settings-links:hover i{
    color:black !important
}
/* .list-card h6{
    
}

.card-outer{
    
} */

